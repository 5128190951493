import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  textCreditCard: {
    fontFamily: "Montserrat",
    fontSize: "1rem",
    fontWeight: 300,
    color: theme.palette.text.secondary,
    width: "29.688rem",
    [theme.breakpoints.down(1200)]: {
      margin: "0 auto",
    },
    [theme.breakpoints.down(600)]: {
      width: 350,
      margin: "0 auto",
      marginTop: "2rem",
      fontSize: "0.75rem",
    },
    "& span": {
      fontWeight: "bold",
    },
  },
  container: {
    display: "flex",
  },
  left: {
    height: "22.5rem",
    display: "flex",
    alignItems: "start",
    justifyContent: "space-between",
    flexDirection: "column",
    [theme.breakpoints.down(1200)]: {
      height: "auto",
      margin: "0 auto",

      "& button": {
        margin: "2rem auto",
        width: 200,
      },
    },
  },
  right: {
    height: "28rem",
    position: "relative",
    width: "100%",
    minWidth: "37rem",
    [theme.breakpoints.down(1280)]: {
      minWidth: "32rem",
    },
    [theme.breakpoints.down(1200)]: {
      height: "auto",
      minWidth: 350,
    },
    "& img": {
      top: "-5rem",
      zIndex: 9,
      position: "absolute",
      [theme.breakpoints.down(1200)]: {
        top: "-4rem",
        zIndex: 9,
        position: "relative",
        margin: "0 auto",
      },
      [theme.breakpoints.down(600)]: {
        width: 330,
        top: "-1rem",
        margin: "0 auto",
      },
    },
  },
}));
