import React from "react";
import { useAuth } from "@hooks";
import { useStyles } from "./index.style";
import Timeline from "./Timeline/index";
import { ButtonPrimary } from "@components/Reusables";

interface Step2CreditCardProps {
  onContinueClick: () => void;
}
const Step3CreditCard = ({ onContinueClick }: Step2CreditCardProps) => {
  const classes = useStyles();

  useAuth({ redirectIfNotLogged: true });
  return (
    <section className={classes.container}>
      <Timeline />
      <ButtonPrimary
        text="Terminar misión"
        noDegraded
        secondaryVersion
        className={classes.button}
        onClick={onContinueClick}
      />
    </section>
  );
};

export default Step3CreditCard;
