import React from "react";
import { useAuth } from "@hooks";
import { useStyles } from "./index.style";
import { CreditCardStep2 } from "images/CreditCard";
import { ButtonPrimary } from "@components/Reusables";
import { useMediaQuery } from "@material-ui/core";
import { BILLED_AMOUNT, BILLING_DATE, PAYMENT_DATE } from "@images";

interface Step2CreditCardProps {
  onContinueClick: () => void;
}
const Step2CreditCard = ({ onContinueClick }: Step2CreditCardProps) => {
  const classes = useStyles();
  const isMobile = useMediaQuery("(max-width: 1200px)");

  useAuth({ redirectIfNotLogged: true });
  return !isMobile ? (
    <section className={classes.container}>
      <div className={classes.positionImage}>
        <img className="creditCard" src={CreditCardStep2} />
        <div className="text1">
          <strong> Monto facturado: </strong>lo que debemos pagar de la tarjeta
          de crédito este mes.
        </div>
        <div className="text2">
          <strong> Fecha de facturación:</strong> el momento en que termina un
          mes de cobro y empieza el otro, la cuál puede no coincidir con las
          fechas de inicio de mes calendario.
        </div>
        <div className="text3">
          <strong> Fecha a pagar: </strong>una vez determinado el monto
          facturado hay un <strong>plazo de 10-15 días</strong> para realizar su
          pago. Este es el hito<strong> más importante,</strong> porque si no
          pagamos el monto facturado se cobran{" "}
          <strong>intereses rotativos </strong> y otros posibles cobros.
        </div>
      </div>
      <ButtonPrimary
        text="Siguiente"
        noDegraded
        secondaryVersion
        onClick={onContinueClick}
      />
    </section>
  ) : (
    <>
      <section className={classes.containermobile}>
        <img src={BILLED_AMOUNT} />
        <span>
          <strong>Monto facturado:</strong> lo que debemos pagar de la tarjeta
          de crédito este mes.
        </span>
        <img src={BILLING_DATE} />
        <span>
          <strong> Fecha de facturación:</strong> el momento en que termina un
          mes de cobro y empieza el otro, la cuál puede no coincidir con las
          fechas de inicio de mes calendario.
        </span>{" "}
        <img src={PAYMENT_DATE} />
        <span>
          <strong>Fecha a pagar:</strong> una vez determinado el monto facturado
          hay un <strong>plazo de 10-15 días</strong> para realizar su pago.
          Este es el hito más <strong>importante</strong>, porque si no pagamos
          el monto facturado se cobran <strong>intereses rotativos</strong> y
          otros posibles cobros.
        </span>
      </section>
      <ButtonPrimary
        className={classes.buttonPrimary}
        text="Siguiente"
        noDegraded
        secondaryVersion
        onClick={onContinueClick}
      />
    </>
  );
};

export default Step2CreditCard;
