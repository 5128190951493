import React from "react";
import { PageProps } from "gatsby";
import { makeStyles, useMediaQuery } from "@material-ui/core";
import { useAuth } from "@hooks";
import { useStyles } from "./index.style";
import { CreditCardStep1 } from "images/CreditCard";
import { ButtonPrimary } from "@components/Reusables";

interface Step1CreditCardProps {
  onContinueClick: () => void;
}

const Step1CreditCard = ({ onContinueClick }: Step1CreditCardProps) => {
  const classes = useStyles();
  const isMobile = useMediaQuery("(max-width: 1200px)");
  useAuth({ redirectIfNotLogged: true });
  return (
    <section className={classes.container}>
      {!isMobile && (
        <div className={classes.left}>
          <div className={classes.textCreditCard}>
            Lo más importante al usar tu tarjeta de crédito es tener claros los
            <span>
              {" "}
              plazos de los pagos para evitar pagar intereses y comisiones
              extra.{" "}
            </span>
            Cuando compras con crédito llega un momento en el mes que debes
            pagar al banco por todo lo que compraste ese mes con tu tarjeta,
            además de otros costos asociados a tu tarjeta, como comisiones.
          </div>

          <ButtonPrimary
            noDegraded
            onClick={onContinueClick}
            secondaryVersion
            text="Siguiente"
          />
        </div>
      )}
      <div className={classes.right}>
        <img src={CreditCardStep1} />
        {isMobile && (
          <div className={classes.left}>
            <div className={classes.textCreditCard}>
              Lo más importante al usar tu tarjeta de crédito es{" "}
              <span>
                {" "}
                tener claros los plazos de los pagos para evitar pagar intereses
                y comisiones extra.{" "}
              </span>
              Cuando compras con crédito llega un momento en el mes que debes
              pagar al banco por todo lo que compraste ese mes con tu tarjeta,
              además de otros costos asociados a tu tarjeta, como comisiones.
            </div>

            <ButtonPrimary
              noDegraded
              onClick={onContinueClick}
              secondaryVersion
              text="Siguiente"
            />
          </div>
        )}
      </div>
    </section>
  );
};

export default Step1CreditCard;
