import React, { useState } from "react";
import { useAuth } from "@hooks";
import { useStyles } from "./index.style";
import {
  clickIcon,
  recomendationIcon,
  TIME_LINE_1,
  TIME_LINE_2,
  TIME_LINE_3,
  TIME_LINE_4,
} from "@images";
import { useMediaQuery } from "@material-ui/core";

const Timeline = () => {
  useAuth({ redirectIfNotLogged: true });
  const [stepLine, setStepLine] = useState(1);
  const [isReverse, setReverse] = useState(false);
  const classes = useStyles({ zIndex: stepLine });
  const isMobile = useMediaQuery("(max-width: 1200px)");

  let descriptionCard: string;
  let recommendationDescription: string;
  let titleCard: string;

  switch (stepLine) {
    case 1:
      descriptionCard =
        "Esto lo haces durante todo un mes, pero tienes fechas de facturación determinadas y los plazos pueden variar según las condiciones de cada entidad financiera.";
      recommendationDescription =
        "Recuerda que al pagar con crédito estás usando plata que aún no se retira de tu cuenta, así que asegúrate que vas a tener saldo para pagarla después.";
      titleCard = "1. Usas tu tarjeta";
      break;
    case 2:
      descriptionCard = (
        <span>
          Te van a cobrar todo lo que gastaste{" "}
          <strong> hasta esta fecha.</strong> Si compras algo hoy en adelante va
          a quedar para el próximo mes.
        </span>
      );
      recommendationDescription =
        "Averigua y ten claras las fechas de facturación de cada tarjeta de crédito para saber en qué mes te van a cobrar lo que compraste.";
      titleCard = "2. Te facturan";
      break;
    case 3:
      descriptionCard = (
        <span>
          Fecha límite para pagar, tienes que{" "}
          <strong> pagar antes que esto</strong>! A partir de ese día, el banco
          te <strong>cobrará intereses.</strong>
        </span>
      );
      recommendationDescription =
        "Fija un recordatorio días antes a la fecha de pago para asegurarte que puedas pagar todo lo que corresponda y no se te haga el pago mínimo solamente.";
      titleCard = "3. Pagas";
      break;
    default:
      descriptionCard =
        "Comienza nuevamente el proceso: Usas la tarjeta, te cobran lo gastado y te dan 15 días para pagar. ";
      recommendationDescription =
        "Evita las demoras en los pagos, así no tendrás que pagar intereses o cobros extra. Y si ya te atrasaste, págalo lo antes posible poque hay intereses que se pueden estar cobrando diariamente.";
      titleCard = "4. Repites";
  }

  return (
    <section className={classes.container}>
      <div className={classes.containerImg}>
        <section className={classes.firstImg}>
          <img src={TIME_LINE_1} />
          {isMobile ? (
            <a
              id="one"
              href="#two"
              className="click"
              onClick={() => {
                setStepLine(stepLine + 1);
                setReverse(false);
              }}
            >
              {" "}
            </a>
          ) : (
            <div
              className="click"
              onClick={() => {
                setStepLine(stepLine + 1);
                setReverse(false);
              }}
            />
          )}
        </section>

        <section className={classes.secondImg}>
          <img src={TIME_LINE_2} />
          {isMobile ? (
            <a
              id="two"
              href={isReverse ? "#one" : "#tree"}
              className="click"
              onClick={() => {
                isReverse
                  ? setStepLine(stepLine - 1)
                  : setStepLine(stepLine + 1);
              }}
            >
              {" "}
            </a>
          ) : (
            <div
              className="click"
              onClick={() => {
                isReverse
                  ? setStepLine(stepLine - 1)
                  : setStepLine(stepLine + 1);
              }}
            />
          )}
        </section>

        <section className={classes.treeImg}>
          <img src={TIME_LINE_3} />
          {isMobile ? (
            <a
              id="tree"
              href={isReverse ? "#two" : "#four"}
              className="click"
              onClick={() => {
                isReverse
                  ? setStepLine(stepLine - 1)
                  : setStepLine(stepLine + 1);
              }}
            >
              {" "}
            </a>
          ) : (
            <div
              className="click"
              onClick={() => {
                isReverse
                  ? setStepLine(stepLine - 1)
                  : setStepLine(stepLine + 1);
              }}
            />
          )}
        </section>

        <section className={classes.fourImg}>
          <img src={TIME_LINE_4} />
          {isMobile ? (
            <a
              id="four"
              href={"#tree"}
              className="click"
              onClick={() => {
                setStepLine(stepLine === 4 ? stepLine - 1 : stepLine + 1);
                setReverse(true);
              }}
            >
              {" "}
            </a>
          ) : (
            <div
              className="click"
              onClick={() => {
                setStepLine(stepLine === 4 ? stepLine - 1 : stepLine + 1);
                setReverse(true);
              }}
            />
          )}
        </section>
        <span className="clickIcon">
          <img src={clickIcon} />
          <span>Haz click para mover la línea de tiempo</span>
        </span>
      </div>

      <div className={classes.recommendation}>
        <div className="title">{titleCard}</div>
        <div className="description">{descriptionCard}</div>
        <div className="boxRecommendation">
          <img src={recomendationIcon} />
          <div>
            <div className="title">consejo</div>
            <div className="description">{recommendationDescription}</div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Timeline;
