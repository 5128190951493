import React, { useContext, useState } from 'react';
import { navigate, PageProps } from 'gatsby';
import { makeStyles, useMediaQuery } from '@material-ui/core';
import { LayoutMissionDesktop, LayoutMissionMobile, SEO } from '@components';
import { useAuth } from '@hooks';
import Step1CreditCard from '@components/CreditCard/Step1';
import Step2CreditCard from '@components/CreditCard/Step2';
import Step3CreditCard from '@components/CreditCard/Step3';
import { Route } from '@interfaces';
import { completeMissionMutation, getUserMissions } from '@apollo';
import { useApolloClient } from '@apollo/client';
import { AppContext } from '@context';
import { MissionsInfo } from '@data';
import { nextMissionModalInfo } from '@utils';

const creditCard: React.FC<PageProps> = () => {
  const classes = useStyles();
  const isMobile = useMediaQuery('(max-width: 1200px)');
  const [step, setStep] = useState(0);
  useAuth({ redirectIfNotLogged: true });
  const backClick = step - 1;
  const { userMissions } = getUserMissions();
  const client = useApolloClient();
  const [confettiFire, setConfettiFire] = useState<boolean>(false);
  const { openCongratsModal } = useContext(AppContext);

  const categoryMissions = userMissions.filter(
    (obj) => obj.mission.category == MissionsInfo.LoansDebtCategory.name
  );
  const currentMission = categoryMissions.filter(
    (obj) => obj.mission.id == MissionsInfo.LoansDebtCategory.m3.id
  )[0];

  const handleClick = () => {
    // if (currentMission?.state == "PENDING") {
    try {
      completeMissionMutation(client, currentMission.mission.id);
      setConfettiFire(true);
      openCongratsModal(nextMissionModalInfo(currentMission, categoryMissions));
    } catch (error) {
      /*         console.log(error);
       */
    }
    // } else {
    //   setConfettiFire(true);
    //   openCongratsModal(nextMissionModalInfo(currentMission, categoryMissions));
    // }
  };
  return (
    <>
      <SEO />
      {!isMobile ? (
        <LayoutMissionDesktop
          title={'Cómo usar la Tarjeta de Crédito a tu favor'}
          number={3}
          secondLayoutForShortcuts={step > 0}
          subtitle={
            step === 1 ? (
              '¿En qué me fijo?'
            ) : step === 2 ? (
              <div>
                Explora la línea de tiempo para ver cómo funciona la facturación
                de las tarjetas. Y <br />
                recuerda que los plazos pueden variar según las condiciones de
                tu entidad financiera.
              </div>
            ) : (
              ''
            )
          }
          setPage={setStep}
          confettiFire={confettiFire}
          redirectLink={undefined}
          onHouseClick={() => navigate(Route.creditsDebts)}
          pageNumber={backClick}
        >
          {step === 0 && (
            <Step1CreditCard onContinueClick={() => setStep(step + 1)} />
          )}
          {step === 1 && (
            <Step2CreditCard onContinueClick={() => setStep(step + 1)} />
          )}
          {step === 2 && (
            <Step3CreditCard onContinueClick={() => handleClick()} />
          )}
        </LayoutMissionDesktop>
      ) : (
        <LayoutMissionMobile
          title={'Cómo usar la Tarjeta de Crédito a tu favor'}
          number={3}
          secondLayoutForShortcuts
          subtitle={step === 1 ? '¿En qué me fijo?' : ''}
          pageNumber={2}
          confettiFire={confettiFire}
          redirectLink={Route.creditsDebts}
          onHouseClick={() => navigate(Route.creditsDebts)}
        >
          {step === 0 && (
            <Step1CreditCard onContinueClick={() => setStep(step + 1)} />
          )}{' '}
          {step === 1 && (
            <Step2CreditCard onContinueClick={() => setStep(step + 1)} />
          )}{' '}
          {step === 2 && (
            <Step3CreditCard onContinueClick={() => handleClick()} />
          )}
        </LayoutMissionMobile>
      )}
    </>
  );
};

export default creditCard;

const useStyles = makeStyles((theme) => ({
  stepper: {
    height: 100,
    marginTop: '2rem',
    background: 'none',
    width: '100%',
    maxWidth: 945,
    paddingRight: 100,
    paddingLeft: '4rem',
    margin: '0 auto',
  },
}));
