import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    [theme.breakpoints.down(1200)]: {
      margin: "0 auto",
    },
    "& button": {
      margin: "4rem 0",
      [theme.breakpoints.down(1200)]: {
        margin: "2rem auto",
      },
    },
    "& >div": {
      boxShadow: `4px 4px 20px 2px rgba(0, 0, 0, 0.2)`,
      padding: 20,
      borderRadius: 30,
      [theme.breakpoints.down(1200)]: {
        margin: "0 auto",
      },
      [theme.breakpoints.down(1270)]: {
        width: "95%",
      },
    },
  },
  positionImage: {
    position: "relative",
    fontSize: "0.75rem",
    "& .creditCard": {
      [theme.breakpoints.down(1270)]: {
        width: "95%",
        minWidth: 910,
      },
    },
    "& >.text1, .text2, .text3": {
      position: "absolute",
      width: "26.5rem",
      right: 30,
      [theme.breakpoints.down(1246)]: {
        right: 15,
      },
    },
    "& >.text1": {
      top: "5.1rem",
      [theme.breakpoints.down(1270)]: {
        top: "4.7rem",
      },
    },
    "& >.text2": {
      top: "10.6rem",
      [theme.breakpoints.down(1270)]: {
        top: "9.6rem",
      },
    },
    "& >.text3": {
      top: "19.1rem",
      [theme.breakpoints.down(1270)]: {
        top: "17.1rem",
      },
    },
  },
  containermobile: {
    display: "flex",
    flexDirection: "column",
    boxShadow: "4px 4px 20px 2px rgba(0,0,0,0.2)",
    padding: "2rem 1rem",
    borderRadius: 20,
    marginTop: "1rem",
    maxWidth: 350,
    margin: "0 auto",
    "& img": {
      margin: "1.2rem 0",
    },
    "& span": {
      margin: "0 1.2rem",
      fontSize: "0.875rem",
      color: theme.palette.text.secondary,
    },
  },
  buttonPrimary: {
    display: "flex",
    justifyContent: "center",
    margin: " 2rem auto ",
  },
}));
