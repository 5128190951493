import { makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles<
  Theme,
  {
    zIndex: number;
  }
>((theme) => ({
  container: {},
  containerImg: {
    width: 1200,
    height: 490,
    position: "relative",
    [theme.breakpoints.down(1400)]: {
      width: 1000,
      height: 450,
    },
    [theme.breakpoints.down(1200)]: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "nowrap",
      gap: "1rem",
      overflowX: "auto",
      width: "auto",
      height: 490,
      "& img": {
        maxWidth: "1200px!important",
      },
    },
    "& .clickIcon": {
      bottom: 50,
      left: 112,
      position: "absolute",
      display: "flex",
      width: "12rem",
      fontSize: "0.875rem",
      color: theme.palette.text.secondary,
      alignItems: "center",
      [theme.breakpoints.down(1400)]: {
        bottom: 100,
        right: 0,
      },
      [theme.breakpoints.down(1200)]: {
        bottom: 75,
        left: 45,
      },
    },
  },
  positionImage: {},
  firstImg: {
    position: "absolute",
    top: 0,
    zIndex: ({ zIndex }) => (zIndex === 1 ? 9 : -1),
    "& >.click": {
      position: "absolute",
      width: 35,
      top: "0rem",
      height: "22rem",
      borderRadius: 20,
      cursor: "pointer",
      left: "7rem",
      [theme.breakpoints.down(1400)]: {
        width: 24,
        height: "22rem",
        top: "0rem",
        left: "7.5rem",
      },
    },
  },
  secondImg: {
    marginLeft: "2.2rem",
    position: "absolute",
    top: 0,
    zIndex: ({ zIndex }) => (zIndex === 2 ? 9 : -1),
    "& >.click": {
      position: "absolute",
      width: 35,
      top: "0rem",
      height: "22rem",
      borderRadius: 20,
      cursor: "pointer",
      left: "32.3rem",
      [theme.breakpoints.down(1400)]: {
        width: 24,
        height: "22rem",
        top: "0rem",
        left: "32.5rem",
      },
    },
  },
  treeImg: {
    position: "absolute",
    top: 0,
    marginLeft: "2.2rem",
    zIndex: ({ zIndex }) => (zIndex === 3 ? 9 : -1),
    "& >.click": {
      position: "absolute",
      width: 35,
      top: "0rem",
      height: "22rem",
      borderRadius: 20,
      cursor: "pointer",
      left: "45.8rem",
      [theme.breakpoints.down(1400)]: {
        width: 30,
        height: "22rem",
        top: "0rem",
        left: "45.8rem",
      },
    },
  },
  fourImg: {
    position: "absolute",
    top: 0,
    marginLeft: "2.2rem",
    zIndex: ({ zIndex }) => (zIndex === 4 ? 9 : -1),
    "& >.click": {
      position: "absolute",
      width: 35,
      top: "0rem",
      height: "22rem",
      borderRadius: 20,
      cursor: "pointer",
      left: "59.3rem",
      [theme.breakpoints.down(1400)]: {
        width: 22,
        height: "22rem",
        top: "0rem",
        left: "60rem",
      },
    },
  },
  recommendation: {
    [theme.breakpoints.down(700)]: {
      width: "345px",
      margin: "0 auto",
    },
    "& .title": {
      color: theme.palette.text.primary,
      fontFamily: "Montserrat",
      fontSize: "2rem",
      fontWeight: "bold",
      marginLeft: "3rem",
    },
    "& .description": {
      fontFamily: "Montserrat",
      fontSize: "1rem",
      fontWeight: 300,
      color: theme.palette.text.secondary,
      width: "25rem",
      paddingTop: "1rem",
      [theme.breakpoints.down(700)]: {
        width: "345px",
        margin: "0 auto",
      },
    },
    "& .boxRecommendation": {
      width: "42rem",
      padding: "1rem 1.5rem",
      display: "flex",
      alignItems: "center",
      gap: "2rem",
      boxShadow: `4px 4px 20px 2px rgba(0, 0, 0, 0.2)`,
      borderRadius: 20,
      marginTop: "2rem",
      marginBottom: "5rem",
      [theme.breakpoints.down(700)]: {
        width: "340px",
      },

      "& img": {
        width: "6rem",
      },
      "& .title": {
        color: theme.palette.text.secondary,
        fontFamily: "Montserrat",
        fontSize: "1rem",
        fontWeight: "bold",
        textTransform: "uppercase",
        margin: 0,
      },
      "& .description": {
        fontFamily: "Montserrat",
        fontSize: "0.875rem",
        fontWeight: 300,
        width: "auto",
        paddingTop: "0.5rem",
      },
    },
  },
}));
